import React from "react";

interface Params {
  path: string;
  params: any;
  onSuccess?: (response: any) => void;
  onError?: (error: string) => void;
}

interface IdleApi {
  loading: false;
  get: (params: Params) => Promise<void>;
  post: (params: Params) => Promise<void>;
}

interface LoadingApi {
  loading: true;
}

type Api = IdleApi | LoadingApi;

export default function useApi(): Api {
  const [loading, setLoading] = React.useState(false);

  const handle = React.useCallback(
    async (params: {
      fetch: () => Promise<Response>;
      onSuccess?: (response: any) => void;
      onError?: (error: string) => void;
    }) => {
      setLoading(true);
      try {
        const response = await params.fetch();
        const json = await response.json();
        if (response.ok) {
          if (params.onSuccess !== undefined) {
            params.onSuccess(json);
          }
        } else if (typeof json.error === "string") {
          if (params.onError !== undefined) {
            params.onError(json.error);
          }
        }
      } catch (error) {
        console.error(error);
        if (params.onError !== undefined) {
          params.onError("unknown");
        }
      }
      setLoading(false);
    },
    [setLoading]
  );

  const get = React.useCallback(
    (params: Params) =>
      handle({
        fetch: () => {
          const search = new URLSearchParams(params.params).toString();
          const url = `${process.env.REACT_APP_API_URL}${params.path}?${search}`;
          return fetch(url, {
            method: "GET",
            headers: {
              Accept: "application/json",
            },
            credentials: "include",
          });
        },
        onSuccess: params.onSuccess,
        onError: params.onError,
      }),
    [handle]
  );

  const post = React.useCallback(
    (params: Params) =>
      handle({
        fetch: () => {
          const url = `${process.env.REACT_APP_API_URL}${params.path}`;
          return fetch(url, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(params.params),
            credentials: "include",
          });
        },
        onSuccess: params.onSuccess,
        onError: params.onError,
      }),
    [handle]
  );

  const api = React.useMemo<Api>(() => {
    if (loading) {
      return { loading: true };
    }
    return {
      loading: false,
      get,
      post,
    };
  }, [loading, get, post]);

  return api;
}
