import React from "react";

interface None {
  status: "none";
}

interface Loading {
  status: "loading";
}

interface Success {
  status: "success";
  content: any;
}

interface Error {
  status: "error";
  error: any;
}

export type Result = None | Loading | Success | Error;

export default function useGetApi(path?: string, params?: any): Result {
  const [status, setStatus] = React.useState<
    "none" | "loading" | "success" | "error"
  >("none");
  const [content, setContent] = React.useState<any>(undefined);
  const [error, setError] = React.useState<any>(undefined);

  React.useEffect(() => {
    if (path === undefined) {
      return;
    }
    setStatus("loading");
    const controller = new AbortController();
    const query =
      params === undefined ? "" : `?${new URLSearchParams(params).toString()}`;
    fetch(`${process.env.REACT_APP_API_URL}${path}${query}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      signal: controller.signal,
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((content) => {
        setStatus("success");
        setContent(content);
      })
      .catch((error) => {
        setStatus("error");
        setError(error.message);
      });
    return () => {
      setStatus("none");
      controller.abort();
    };
  }, [path, params]);

  const result = React.useMemo<Result>(() => {
    if (status === "loading") {
      return {
        status: "loading",
      };
    }
    if (status === "success") {
      return {
        status: "success",
        content,
      };
    }
    if (status === "error") {
      return {
        status: "error",
        error,
      };
    }
    return {
      status: "none",
    };
  }, [status, content, error]);

  return result;
}
