import { Box, Container, Typography } from "@mui/material";
import Footer from "./Footer";
import logo from "./logo.svg";
import React from "react";
import useTitle from "./useTitle";

interface Props {
  title: string;
  children: React.ReactNode;
}

export default function Page(props: Props) {
  useTitle(props.title);
  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          my: 10,
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          gap: "20px",
          alignItems: "end",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{ height: "80px", width: "80px" }}
          component="img"
          src={logo}
          alt="logo"
        />
        <Typography component="h1" variant="h3">
          AnonChatGPT
        </Typography>
      </Box>
      {props.children}
      <Footer />
    </Container>
  );
}
