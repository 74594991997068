import { Typography } from "@mui/material";
import "./AnimatedEllipsis.css";

export default function AnimatedEllipsis() {
  return (
    <>
      <span className="dot1">.</span>
      <span className="dot2">.</span>
      <span className="dot3">.</span>
    </>
  );
}
