import { Badge, BadgeMark, Box, Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Copyright from "./Copyright";
import FiberNewIcon from "@mui/icons-material/FiberNew";

export default function Footer() {
  return (
    <Box sx={{ p: 6 }} component="footer">
      <Box
        sx={{
          mb: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          flexWrap: "wrap",
          columnGap: 3,
          rowGap: 1,
        }}
      >
        <Link component={RouterLink} to="/">
          Home
        </Link>
        <Link component={RouterLink} to="/blog">
          Blog &#x1F195;
        </Link>
        <Link component={RouterLink} to="/about">
          About
        </Link>
        <Link component={RouterLink} to="/faq">
          FAQ
        </Link>
        <Link component={RouterLink} to="/terms-of-service">
          Terms of Service
        </Link>
        <Link component={RouterLink} to="/privacy-policy">
          Privacy Policy
        </Link>
      </Box>
      <Copyright />
    </Box>
  );
}
