import { Box, Typography } from "@mui/material";
import ChatMessage from "./ChatMessage";
import Recaptcha from "react-google-recaptcha";
import nullthrows from "nullthrows";
import useApi from "./useApi";
import ChatMessageLoading from "./ChatMessageLoading";
import useAnalytics from "./useAnalytics";

interface Props {
  onSuccess: () => void;
}

export default function ChatMessageVerification(props: Props) {
  const analytics = useAnalytics();
  const api = useApi();

  if (api.loading) {
    return <ChatMessageLoading />;
  }

  return (
    <ChatMessage role="assistant">
      <Box sx={{ p: 2 }}>
        <Typography>
          To ensure the security of the service and prevent abuse, please verify
          that you're human.
        </Typography>
        <Box sx={{ p: 2, display: "flex", justifyContent: "center" }}>
          <Recaptcha
            sitekey={nullthrows(process.env.REACT_APP_RECAPTCHA_SITE_KEY)}
            onChange={(token) => {
              api.post({
                path: "/verify/recaptcha",
                params: { token },
                onSuccess: () => {
                  props.onSuccess();
                  analytics.event("verify_success");
                },
                onError: (error) => {
                  analytics.event("verify_error");
                },
              });
              analytics.event("verify_submit");
            }}
          />
        </Box>
      </Box>
    </ChatMessage>
  );
}
