import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";

import HomePage from "./HomePage";
import PrivacyPolicyPage from "./PrivacyPolicyPage";
import TermsOfServicePage from "./TermsOfServicePage";
import AboutPage from "./AboutPage";
import FrequentlyAskedQuestionsPage from "./FrequentlyAskedQuestionsPage";
import AnalyticsProvider from "./AnalyticsProvider";
import BlogPostPage from "./BlogPostPage";
import BlogHomePage from "./BlogHomePage";

const theme = createTheme({
  palette: {
    mode: "dark",
  },
  typography: {
    fontFamily: "monospace",
  },
});

function App() {
  return (
    <AnalyticsProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/faq" element={<FrequentlyAskedQuestionsPage />} />
            <Route path="/terms-of-service" element={<TermsOfServicePage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/blog" element={<BlogHomePage />} />
            <Route path="/blog/:path" element={<BlogPostPage />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </AnalyticsProvider>
  );
}

export default App;
