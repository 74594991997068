import { Box, Card } from "@mui/material";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import avatarAssistant from "./avatar-assistant.svg";
import avatarUser from "./avatar-user.svg";
import AnimatedEllipsis from "./AnimatedEllipsis";

type Role = "assistant" | "user";

interface Props {
  role: Role;
  children: React.ReactNode;
}

export default function ChatMessage(props: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        mt: 3,
        justifyContent: props.role === "user" ? "end" : "start",
      }}
    >
      {props.role === "assistant" && (
        <Box
          sx={{ height: "40px" }}
          component="img"
          src={avatarAssistant}
          alt="Assistant"
        />
      )}
      <Card
        sx={{
          ml: props.role === "user" ? 6 : 1,
          mr: props.role === "user" ? 1 : 6,
        }}
      >
        {props.children}
      </Card>
      {props.role === "user" && (
        <Box
          sx={{ height: "40px" }}
          component="img"
          src={avatarUser}
          alt="User"
        />
      )}
    </Box>
  );
}
