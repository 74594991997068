import type GPT3Tokenizer from "gpt3-tokenizer";

let tokenizer: null | GPT3Tokenizer = null;

let loading = false;

function loadTokenizer() {
  if (loading) {
    return;
  }
  loading = true;

  import("gpt3-tokenizer").then((module) => {
    const GPT3Tokenizer = module.default;
    tokenizer = new GPT3Tokenizer({ type: "gpt3" });
  });
}

export default function lazyTokenCount(text: string): number {
  if (text.length !== 0) {
    // Only load the tokenizer when the function gets non-trivial use.
    loadTokenizer();
  }
  if (tokenizer === null) {
    return Math.ceil(text.length / 4);
  }
  return tokenizer.encode(text).bpe.length;
}
