import { Link, Typography } from "@mui/material";
import Page from "./Page";

export default function FrequentlyAskedQuestionsPage() {
  return (
    <Page title="Frequently Asked Questions">
      <Typography sx={{ mt: 6 }} variant="h3" gutterBottom>
        Frequently Asked Questions
      </Typography>
      <Typography sx={{ mt: 6 }} variant="h5" gutterBottom>
        How do I type questions spanning multiple lines?
      </Typography>
      <Typography paragraph={true} variant="body1">
        If you want to enter a multi-line question in AnonChatGPT, it's
        important to know that pressing enter will submit the query. To add a
        new line to your question, you'll need to use the shift+enter key
        combination. This will create a new line within your question without
        submitting it.
      </Typography>
      <Typography sx={{ mt: 6 }} variant="h5" gutterBottom>
        Will AnonChatGPT store my questions and responses?
      </Typography>
      <Typography paragraph={true} variant="body1">
        No. AnonChatGPT does not store any of the questions you submit, nor any
        of the responses you receive from ChatGPT. While OpenAI may store data
        related to your queries and responses, if you're using AnonChatGPT,
        their servers can't track your IP or any other identifiable information
        that could be linked to you.
      </Typography>
      <Typography sx={{ mt: 6 }} variant="h5" gutterBottom>
        What if I get blocked for submitting too many questions?
      </Typography>
      <Typography paragraph={true} variant="body1">
        We may temporarily block users who send too many questions in a short
        amount of time. This is to ensure that everyone gets a fair share of the
        free service. If you find yourself blocked, you have two options. The
        first option is to wait for approximately 30 minutes and try again.
        Alternatively, you can create a regular account for ChatGPT on OpenAI's
        website by following this link:{" "}
        <Link href="https://beta.openai.com/signup/" target="_blank">
          https://beta.openai.com/signup/
        </Link>
        .
      </Typography>
      <Typography sx={{ mt: 6 }} variant="h5" gutterBottom>
        Does AnonChatGPT use cookies?
      </Typography>
      <Typography paragraph={true} variant="body1">
        We use cookies the purpose of remembering captcha human verification as
        a convenience for you. When you complete a captcha challenge, we may
        store a cookie on your device to remember that you have passed the
        verification. This means that the verification will be remembered across
        site reloads, and you won't have to re-enter a captcha for every single
        query.
      </Typography>
      <Typography sx={{ mt: 6 }} variant="h5" gutterBottom>
        Does AnonChatGPT have social media presence?
      </Typography>
      <Typography paragraph={true} variant="body1">
        We have presence on{" "}
        <Link href="https://www.reddit.com/user/AnonChatGPT" target="_blank">
          Reddit
        </Link>
        ,{" "}
        <Link
          href="https://www.facebook.com/people/Anonchatgpt/61550702066134/"
          target="_blank"
        >
          Facebook
        </Link>
        ,{" "}
        <Link href="https://twitter.com/AnonChatGPT" target="_blank">
          Twitter
        </Link>{" "}
        and{" "}
        <Link
          href="https://news.ycombinator.com/item?id=35680029"
          target="_blank"
        >
          HackerNews
        </Link>
        . Or send us an email at{" "}
        <Link href="mailto:contact@anonchatgpt.com">
          contact@anonchatgpt.com
        </Link>
        .
      </Typography>
    </Page>
  );
}
