import { Box, CircularProgress, Typography } from "@mui/material";
import Page from "./Page";
import useGetApi from "./useGetApi";
import { Navigate, useParams } from "react-router-dom";
import nullthrows from "nullthrows";
import BlogPostInnerPage from "./BlogPostInnerPage";
import AnimatedEllipsis from "./AnimatedEllipsis";

interface Post {
  path: string;
  title: string;
  content: string;
  publishedAt: Date;
  previousPath?: string;
  previousTitle?: string;
  nextPath?: string;
  nextTitle?: string;
}

function parsePost(response: any): Post {
  const path = response?.post?.path;
  const title = response?.post?.title;
  const content = response?.post?.content;
  const publishedAt = response?.post?.publishedAt;
  const previousPath = response?.post?.previousPath;
  const previousTitle = response?.post?.previousTitle;
  const nextPath = response?.post?.nextPath;
  const nextTitle = response?.post?.nextTitle;
  return {
    path: typeof path === "string" ? path : "",
    title: typeof title === "string" ? title : "",
    content: typeof content === "string" ? content : "",
    publishedAt: new Date(publishedAt),
    previousPath: typeof previousPath === "string" ? previousPath : undefined,
    previousTitle:
      typeof previousTitle === "string" ? previousTitle : undefined,
    nextPath: typeof nextPath === "string" ? nextPath : undefined,
    nextTitle: typeof nextTitle === "string" ? nextTitle : undefined,
  };
}

export default function BlogPostPage() {
  const params = useParams();
  const path = nullthrows(params.path);
  const api = useGetApi(`/blog/posts/${path}`);

  if (api.status === "success") {
    const post = parsePost(api.content);
    return <BlogPostInnerPage post={post} />;
  }

  if (api.status === "error") {
    if (api.error === "Not Found") {
      return <Navigate to="/blog" />;
    }

    return (
      <Page title="Loading blog post...">
        <Typography align="center" sx={{ my: 16 }} color="error">
          Error loading blog post: {api.error}
        </Typography>
      </Page>
    );
  }

  return (
    <Page title="Loading blog post...">
      <Typography align="center" sx={{ my: 16 }}>
        Loading blog post
        <AnimatedEllipsis />
      </Typography>
    </Page>
  );
}
