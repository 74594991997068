import { Link, Typography } from "@mui/material";
import Page from "./Page";
import { Link as RouterLink } from "react-router-dom";

interface Post {
  path: string;
  title: string;
  publishedAt: Date;
}
interface Props {
  posts: Post[];
}

export default function BlogHomeInnerPage(props: Props) {
  return (
    <Page title="Blog">
      <Typography variant="h3" gutterBottom>
        Blog directory
      </Typography>
      {props.posts.map((post) => (
        <Typography key={post.path} gutterBottom>
          <Link component={RouterLink} to={`/blog/${post.path}`}>
            {post.title}
          </Link>{" "}
          ({post.publishedAt.toDateString()})
        </Typography>
      ))}{" "}
    </Page>
  );
}
