import { Box } from "@mui/material";
import AnimatedEllipsis from "./AnimatedEllipsis";
import ChatMessage from "./ChatMessage";

export default function ChatMessageLoading() {
  return (
    <ChatMessage role="assistant">
      <Box sx={{ p: 2 }}>
        <AnimatedEllipsis />
      </Box>
    </ChatMessage>
  );
}
