import { Box, Card } from "@mui/material";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import avatarAssistant from "./avatar-assistant.svg";
import avatarUser from "./avatar-user.svg";
import AnimatedEllipsis from "./AnimatedEllipsis";
import ChatMessage from "./ChatMessage";

type Role = "assistant" | "user";

interface Props {
  role: Role;
  content: string;
}

export default function ChatMessageMarkdown(props: Props) {
  return (
    <ChatMessage role={props.role}>
      <Box sx={{ px: 2 }}>
        <ReactMarkdown>{props.content}</ReactMarkdown>
      </Box>
    </ChatMessage>
  );
}
