import { Grid, Link, Typography } from "@mui/material";
import Page from "./Page";
import ReactMarkdown from "react-markdown";
import { Link as RouterLink } from "react-router-dom";

interface Post {
  path: string;
  title: string;
  content: string;
  publishedAt: Date;
  previousPath?: string;
  previousTitle?: string;
  nextPath?: string;
  nextTitle?: string;
}
interface Props {
  post: Post;
}

export default function BlogPostInnerPage(props: Props) {
  return (
    <Page title={props.post.title}>
      <Typography variant="h3" gutterBottom>
        {props.post.title}
      </Typography>
      <Typography variant="body2" gutterBottom>
        Publised on {props.post.publishedAt.toDateString()}
      </Typography>
      <ReactMarkdown
        components={{
          h2: (props) => (
            <Typography sx={{ mt: 4, mb: 2 }} variant="h5">
              {props.children}
            </Typography>
          ),
          p: (props) => (
            <Typography sx={{ mt: 2, mb: 2 }}>{props.children}</Typography>
          ),
          a: (props) => (
            <Link href={props.href} target="_blank">
              {props.children}
            </Link>
          ),
        }}
      >
        {props.post.content}
      </ReactMarkdown>
      <Grid container spacing={2} sx={{ mt: 4 }}>
        <Grid
          item
          xs={6}
          sx={{
            textAlign: "left",
          }}
        >
          {props.post.previousPath && (
            <Typography noWrap>
              <Link
                component={RouterLink}
                to={`/blog/${props.post.previousPath}`}
              >
                &#60; Previous: {props.post.previousTitle}
              </Link>
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "right", textOverflow: "ellipsis" }}>
          {props.post.nextPath && (
            <Typography noWrap>
              <Link component={RouterLink} to={`/blog/${props.post.nextPath}`}>
                Next: {props.post.nextTitle} &#62;
              </Link>
            </Typography>
          )}
        </Grid>
      </Grid>
    </Page>
  );
}
