import { IconButton, InputBase, Paper, Typography } from "@mui/material";
import { queryTextMaxLength, queryTextMaxTokens } from "./constants";
import React from "react";
import { Send as SendIcon } from "@mui/icons-material";
import lazyTokenCount from "./lazyTokenCount";

interface Props {
  disabled: boolean;
  onSubmit: (queryText: string) => void;
}

export default function ChatPrompt(props: Props) {
  const [queryText, setQueryText] = React.useState<string>("");
  const error = React.useMemo(() => {
    const tokens = lazyTokenCount(queryText);
    if (tokens > queryTextMaxTokens) {
      return "Your question is too long";
    }
    return null;
  }, [queryText]);
  const canSubmit = !props.disabled && error === null && queryText !== "";
  const submit = React.useCallback(() => {
    if (canSubmit) {
      props.onSubmit(queryText);
      setQueryText("");
    }
  }, [canSubmit, props.onSubmit, queryText, setQueryText]);
  return (
    <>
      <Paper
        component="form"
        sx={{
          mt: 3,
          mx: "auto",
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Ask ChatGPT"
          inputProps={{
            "aria-label": "question for chatgpt",
            maxLength: queryTextMaxLength,
          }}
          multiline
          value={queryText}
          onChange={(event) => {
            setQueryText(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter" && !event.shiftKey) {
              submit();
              event.preventDefault();
            }
          }}
          disabled={props.disabled}
        />
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="submit question"
          onClick={submit}
          disabled={!canSubmit}
        >
          <SendIcon />
        </IconButton>
      </Paper>
      {error !== null && (
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      )}
    </>
  );
}
