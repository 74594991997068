import { Typography } from "@mui/material";
import Page from "./Page";
import useGetApi from "./useGetApi";
import AnimatedEllipsis from "./AnimatedEllipsis";
import BlogHomeInnerPage from "./BlogHomeInnerPage";

interface Post {
  path: string;
  title: string;
  publishedAt: Date;
}

function parsePost(response: any): Post {
  const path = response?.path;
  const title = response?.title;
  const publishedAt = response?.publishedAt;
  return {
    path: typeof path === "string" ? path : "",
    title: typeof title === "string" ? title : "",
    publishedAt: new Date(publishedAt),
  };
}

function parsePosts(response: any): Post[] {
  return response?.posts?.map?.(parsePost) ?? [];
}

export default function BlogHomePage() {
  const api = useGetApi(`/blog/posts`);

  if (api.status === "success") {
    const posts = parsePosts(api.content);
    return <BlogHomeInnerPage posts={posts} />;
  }

  if (api.status === "error") {
    return (
      <Page title="Blog">
        <Typography align="center" sx={{ my: 16 }} color="error">
          Error loading blog posts: {api.error}
        </Typography>
      </Page>
    );
  }

  return (
    <Page title="Blog">
      <Typography align="center" sx={{ my: 16 }}>
        Loading blog posts
        <AnimatedEllipsis />
      </Typography>
    </Page>
  );
}
